<template>
    <div class="container">
        <div class="info-container">
            <div class="info-title">
                <span>{{detail.name}}宝宝的测评报告详情</span>
            </div>
            <div class="info-sub">
                <div class="info-sub-item">
                    <span class="key">宝宝月龄: </span>
                    <span class="value">{{monthAge || '-'}}</span>
                </div>
                <div class="info-sub-item">
                    <span class="key">填表人姓名: </span>
                    <span class="value">{{detail.user_name}}
                        <span class="preparer-role" v-if="detail.relation">{{detail.relation || '-'}}</span>
                    </span>
                </div>
                <div class="info-sub-item">
                    <span class="key">填表人学历: </span>
                    <span class="value">{{detail.user_educational || '-'}}</span>
                </div>
                <div class="info-sub-item">
                    <span class="key">填表人职业: </span>
                    <span class="value">{{detail.user_career || '-'}}</span>
                </div>
                <div class="info-sub-item">
                    <span class="key">填表人联系方式: </span>
                    <span class="value">{{detail.user_phone || '-'}}</span>
                </div>
                <div class="info-sub-item">
                    <span class="key">所选课程: </span>
                    <span class="value">{{detail.experience_course_name || '-'}}</span>
                </div>
                <div class="info-sub-item">
                    <span class="key">所选园区: </span>
                    <span class="value">{{detail.school_name || '-'}}</span>
                </div>
            </div>
            <div class="info-btn">
                <!-- <el-button type="primary" plain icon="iconfont icon-delete"> 删除</el-button>     -->
            </div>
        </div>
        <div class="tabs">
            <el-tabs class="my-tabs" v-model="activeTab">
                <el-tab-pane label="宝宝信息" name="baby-info" class="baby-info-pane">
                    <baby-info :id='id' ></baby-info>
                </el-tab-pane>

                <el-tab-pane label="答题详情" name="test-detail" class="test-detail-pane">
                    <test-detail :id='evaluationId' v-if="activeTab === 'test-detail'"></test-detail>
                </el-tab-pane>

                <el-tab-pane label="测评结果" name="test-result" class="test-result-pane">
                    <test-result :id='recordId' v-if="activeTab === 'test-result'"></test-result>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import Core from '@/core';

export default {
    components: {
        BabyInfo: () => import('./components/BabyInfo'),
        TestDetail: () => import('./components/TestDetail'),
        TestResult: () => import('./components/TestResult'),
    },
    data() {
        return {
            userType: Core.Data.getUserType(),

            id: '',
            recordId: '',
            evaluationId: '',

            activeTab: 'baby-info',

            // 宝宝信息
            detail: {},

            monthAge: '',
        };
    },
    watch: {},
    computed: {},
    created() {
        this.id = this.$route.query.id || '';
        this.recordId = this.$route.query.record_id || '';
        this.evaluationId = this.$route.query.evaluation_id || '';
        this.getDetail();
    },
    mounted() {},
    methods: {
        /* getTabInfo(type) {  // tab切换
            switch (type) {
                case 'baby-info':
                    this.getDetail();
                    break;
                case 'test-detail':
                    this.getEvaluationDetail();
                    break;
                case 'test-result':
                    this.getEvaluationResult()
                    break;
                default:
                    break;
            }
        }, */
        getDetail() { // 测评信息/宝宝信息
            Core.operationApi.Record.detail(this.id).then((res) => {
                this.detail = res.detail;
                this.monthAge = parseFloat((((new Date()) - this.detail.birthday * 1000) / (1000 * 60 * 60 * 24 * 30)).toFixed(1))
                console.log("getDetail -> monthAge", this.monthAge)
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .info-container {
        width: 100%;
        background: #ffffff;
        box-sizing: border-box;
        padding: 0 30px;
        border-bottom: 0;
        position: relative;
        .info-title {
            padding-top: 20px;
            font-size:20px;
            font-weight:500;
            color:$color-black;
            line-height:28px;
        }
        .info-sub {
            @include flex(row, flex-start, center);
            flex-wrap: wrap;
            padding-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid $border-color;
            .info-sub-item {
                width: 25%;
                margin-top: 10px;
                // flex: 1;
                span{
                    font-size: $font-size-base;
                    font-weight: 400;
                    line-height: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .key {
                    color:$color-black-light;
                }
                .value {
                    color:$color-black;
                }
                .preparer-role {
                    margin-left: 3px;
                    color: #EC5D6E;
                    background:rgba(236,93,110,0.17);
                    border-radius:4px;
                    padding: 3px 6px;
                    font-size:14px;
                }
            }
        }
        .info-btn {
            position: absolute;
            right: 30px;
            top: 22px;
        }
    }
    .tabs {
        width: 100%;
        box-sizing: border-box;
    }
}
</style>
